body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.dhx_cal_tab {
    width: 38px;
}
div.day_tab {
    left: 8px;
}
div.dhx_cal_prev_button {
    right: 56px;
}
div.dhx_cal_next_button {
    right: 30px;
}
div.dhx_cal_today_button {
    width: 30px;
    right: 2px;
}

div.dc_dhx_tab_custom {
    position: absolute;
    white-space: nowrap;
    top: -15px;
    right: auto;
    width: 55px;
    box-sizing: content-box;
    line-height: 30px;
    height: 29px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 1px;
    border-radius: 0;
    cursor: pointer;
    border: 1px solid #0288d1;
/*    color: #0288d1; */
    color: #ffffff;
    font-size: inherit;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: Roboto,Arial;
}

div#tab_dottori {

}

div#tab_task {
    left: 55px;
}

div#tab_ann {
    left: 0px;
}

div#tab_sedi {
    left: 112px;
    width: 80px;
}

div#tab_sedi select {
    font-size: large;
    font-weight: bold;
    background-color: inherit;
    color: inherit;
    padding: 1px;
    margin: 2px;

}


div#tab_task.active, div#tab_sedi.active, div#tab_ann.active {
    text-decoration: none;
    font-weight: 500;
    font-size: inherit;
    color: #fff;
    border: 1px solid #0288d1;
    background-color: #0288d1;
    z-index: 25;
}

.stato-esecuzione {
    cursor:pointer;
}

.dhx_cal_event.event_color_E91E63 div,
.dhx_cal_event_line.event_color_E91E63{
    background-color: #E91E63 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_E91E63{
    background-color: #E91E63 !important;
}
.dhx_cal_event_clear.event_color_E91E63{
    color:#E91E63 !important;
}


.dhx_cal_event.event_color_9C27B0 div,
.dhx_cal_event_line.event_color_9C27B0{
    background-color: #9C27B0 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_9C27B0{
    background-color: #9C27B0 !important;
}
.dhx_cal_event_clear.event_color_9C27B0{
    color:#9C27B0 !important;
}

.dhx_cal_event.event_color_738277 div,
.dhx_cal_event_line.event_color_738277{
    background-color: #738277 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_738277{
    background-color: #738277 !important;
}
.dhx_cal_event_clear.event_color_738277{
    color:#738277 !important;
}

.dhx_cal_event.event_color_673AB7 div,
.dhx_cal_event_line.event_color_673AB7{
    background-color: #673AB7 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_673AB7{
    background-color: #673AB7 !important;
}
.dhx_cal_event_clear.event_color_673AB7{
    color:#673AB7 !important;
}


.dhx_cal_event.event_color_00BCD4 div,
.dhx_cal_event_line.event_color_00BCD4{
    background-color: #00BCD4 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_00BCD4{
    background-color: #00BCD4 !important;
}
.dhx_cal_event_clear.event_color_00BCD4{
    color:#00BCD4 !important;
}


.dhx_cal_event.event_color_009688 div,
.dhx_cal_event_line.event_color_009688{
    background-color: #009688 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_009688{
    background-color: #009688 !important;
}
.dhx_cal_event_clear.event_color_009688{
    color:#009688 !important;
}


.dhx_cal_event.event_color_4CAF50 div,
.dhx_cal_event_line.event_color_4CAF50{
    background-color: #4CAF50 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_4CAF50{
    background-color: #4CAF50 !important;
}
.dhx_cal_event_clear.event_color_4CAF50{
    color:#4CAF50 !important;
}


.dhx_cal_event.event_color_CDDC39 div,
.dhx_cal_event_line.event_color_CDDC39{
    background-color: #CDDC39 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_CDDC39{
    background-color: #CDDC39 !important;
}
.dhx_cal_event_clear.event_color_CDDC39{
    color:#CDDC39 !important;
}


.dhx_cal_event.event_color_FFC0CB div,
.dhx_cal_event_line.event_color_FFC0CB{
    background-color: #FFC0CB !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_FFC0CB{
    background-color: #FFC0CB !important;
}
.dhx_cal_event_clear.event_color_FFC0CB{
    color:#FFC0CB !important;
}


.dhx_cal_event.event_color_FFEB3B div,
.dhx_cal_event_line.event_color_FFEB3B{
    background-color: #FFEB3B !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_FFEB3B{
    background-color: #FFEB3B !important;
}
.dhx_cal_event_clear.event_color_FFEB3B{
    color:#FFEB3B !important;
}


.dhx_cal_event.event_color_FF9800 div,
.dhx_cal_event_line.event_color_FF9800{
    background-color: #FF9800 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_FF9800{
    background-color: #FF9800 !important;
}
.dhx_cal_event_clear.event_color_FF9800{
    color:#FF9800 !important;
}


.dhx_cal_event.event_color_795548 div,
.dhx_cal_event_line.event_color_795548{
    background-color: #795548 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_795548{
    background-color: #795548 !important;
}
.dhx_cal_event_clear.event_color_795548{
    color:#795548 !important;
}


.dhx_cal_event.event_color_9E9E9E div,
.dhx_cal_event_line.event_color_9E9E9E{
    background-color: #9E9E9E !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_9E9E9E{
    background-color: #9E9E9E !important;
}
.dhx_cal_event_clear.event_color_9E9E9E{
    color:#9E9E9E !important;
}

.dhx_cal_event.event_color_EA899A div,
.dhx_cal_event_line.event_color_EA899A{
    background-color: #EA899A !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_EA899A{
    background-color: #EA899A !important;
}
.dhx_cal_event_clear.event_color_EA899A{
    color:#EA899A !important;
}

.dhx_cal_event.event_color_00BB2D div,
.dhx_cal_event_line.event_color_00BB2D {
    background-color: #00BB2D !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_00BB2D {
    background-color: #00BB2D !important;
}
.dhx_cal_event_clear.event_color_00BB2D {
    color:#00BB2D !important;
}

.dhx_cal_event.event_color_F5D033 div,
.dhx_cal_event_line.event_color_F5D033 {
    background-color: #F5D033 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_F5D033 {
    background-color: #F5D033 !important;
}
.dhx_cal_event_clear.event_color_F5D033 {
    color: #F5D033 !important;
}

.dhx_cal_event.event_color_FF0000 div,
.dhx_cal_event_line.event_color_FF0000{
    background-color: #FF0000 !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_FF0000{
    background-color: #FF0000 !important;
}
.dhx_cal_event_clear.event_color_FF0000{
    color:#FF0000 !important;
}



div.dhx_event_move.dhx_title {
    display: none;
}

.dhx_cal_event.event_color_FFA540 div,
.dhx_cal_event_line.event_color_FFA540{
    background-color: #FFA540 !important;
    border-color: #FF0000 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_FFA540{
    background-color: #FFA540 !important;
}
.dhx_cal_event_clear.event_color_FFA540{
    color:#FFA540 !important;
}

.dhx_cal_event.event_color_F5D033 div,
.dhx_cal_event_line.event_color_F5D033{
    background-color: #F5D033 !important;
    border-color: #9E9E9E !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_F5D033{
    background-color: #F5D033 !important;
}
.dhx_cal_event_clear.event_color_F5D033{
    color:#F5D033 !important;
}

.dhx_cal_event.event_color_B5651D div,
.dhx_cal_event_line.event_color_B5651D{
    background-color: #B5651D !important;
    border-color: #732d16 !important;
}
.dhx_cal_event.dhx_cal_editor.event_color_B5651D{
    background-color: #B5651D !important;
}
.dhx_cal_event_clear.event_color_B5651D {
    color: #B5651D !important;
}


.dhx_cal_event.riscuotere div {
    border-width: thick;
    border-color: #FF0000 !important;
}

.dhx_cal_event.riscuoterepoco div {
    border-width: medium;
    border-color: orange !important;
}


.MuiTableCell-root {
    padding: 2px 3px !important;
    font-size: 10pt !important;
}

.MuiTab-wrapper {
    font-size: .9em !important;
}

.elem {
    float: left;
    display: inline;
    margin: 0px;
    padding: 0px;
}

.elem .MuiFormControlLabel-root {
    margin: 0px;
    padding: 0px 0px 20px 0px;
}


div.dhx_event_move.dhx_title {
    height: 0px;
}

svg.MuiSvgIcon-root {
    font-size: 1.03rem;
}

.gray_section {
    background-color: #5a5a5a;
    opacity: 0.25;
    filter: alpha(opacity=25);
}

.MuiContainer-root {
    margin-top: 15px;
}

.icon_delete {
    display: none !important;
}

div.red {
    background: red;
}

div.orange {
    background: orange;
}

div.yellow {
    background: yellow;
}

